<template>
  <div class="file_wrap">
    <div class="file_box">
      <div class="file_header">
        <img class="less_than" src="/media/img/vector.png" @click="onBackClose" alt="lessthan" />
        <p>파일 업로드</p>
        <img src="/media/img/close_btn.png" @click="onClickClose" alt="닫기" class="cursor" />
      </div>
      <p class="upload_text">{{ isFilename ? '파일명을 설정해주세요.' : '파일을 업로드 해주세요.' }}</p>

      <!--box 1-->

      <div v-show="fileInfo === ''" class="file_upload" v-cloak @drop.prevent="addFile" @dragover.prevent @click="clickInputTag()">
        <img src="/media/img/icon_upload_mp3.png" alt="mp3" class="cursor" />
        <p>클릭하거나 드래그로 mp3 파일을 업로드해주세요.<br />파일 용량은 10MB 이하로 제한됩니다.</p>
        <input class="mp3_input_box" ref="mp3_input" id="input" type="file" accept=".mp3" @change="uploadMp3()" />
      </div>

      <!--box 2-->

      <div class="file_view" v-show="fileInfo !== '' && !isFilename">
        <img class="red_music" src="/media/img/icon_musicfile_38.png" alt="red_music" />
        <p>{{ fileInfo.name }}</p>
        <img src="/media/img/close_btn.png" @click="removeFile()" alt="파일 삭제" />
      </div>
      <!--box 3-->
      <div class="name_wrap" v-show="fileInfo !== '' && isFilename">
        <input class="file_name" :class="{ border_red: filename.length > 10 }" type="text" :value="filename" @input="inputName" placeholder="ex) 오프닝용 멘트" ref="name_input" />
        <img src="/media/img/close_btn.png" @click="removeName()" alt="삭제" />
        <p v-show="isWarn" class="text_warn">파일명은 띄어쓰기 포함 10글자까지만 가능합니다.</p>
      </div>

      <button class="next_button" @click="nextButton()" :class="{ opacity_30: (fileInfo === '' && !isFilename) || (filename.length === 0 && isFilename) }">
        {{ isFilename ? '완료' : '다음' }}
      </button>
    </div>
    <SimpleAlert />
    <LodingCircle v-show="isLoding" />
  </div>
</template>

<script>
import { registMySoundByFile, uploadChosenFile } from '@/service/api/mysound';
import SimpleAlert from '@/components/modal/MusicManagement/SimpleAlert.vue';
import LodingCircle from '@/components/common/LodingCircle.vue';
import VueCookies from 'vue-cookies';
export default {
  name: 'FileUploadModal',
  emits: ['close-modal'],
  components: {
    SimpleAlert,
    LodingCircle,
  },
  props: {
    alertText: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      fileInfo: '',
      isFilename: false,
      filename: '',
      fileUploadInfo: {},
      isLoding: false,
      isWarn: false,
      timer: 0,
    };
  },
  computed: {
    isFinal() {
      return this.$store.getters['simplealert/getIsFinal'];
    },
  },
  watch: {
    isFinal(newData, oldData) {
      if (oldData) this.onClickClose();
    },
  },
  methods: {
    onBackClose() {
      if (this.fileInfo === '') {
        this.$emit('close-modal');
        this.resetInfo();
      } else if (this.isFilename) {
        this.isFilename = false;
        this.filename = '';
      } else if (!this.isFilename && this.filename === '') {
        this.fileInfo = '';
        this.$refs.mp3_input.value = '';
      }
    },
    onClickClose() {
      this.resetInfo();
      this.$emit('close-modal');
    },
    resetInfo() {
      this.isFilename = false;
      this.fileUploadInfo = '';
      this.filename = '';
      this.fileInfo = '';
      this.$refs.mp3_input.value = '';
      this.isLoding = false;
    },
    mp3Filter(info = { name: '', size: 0 }) {
      const { name, size } = info;
      const mb = 1024 * 1024; // 1mb(메가)
      const limitSize = mb * 10;

      if (name.split('.')[1].toLowerCase() !== 'mp3') {
        this.$store.commit('simplealert/open', '업로드한 파일은 지원하지 않는 형식입니다. <br/> 다시 확인 후 업로드 해주세요.');
        return false;
      } else if (limitSize < size) {
        this.$store.commit('simplealert/open', '업로드한 파일 용량이 초과되었습니다. <br/> 다시 확인 후 업로드 해주세요.');
        return false;
      } else {
        return true;
      }
    },
    addFile(e) {
      const files = e.dataTransfer.files;
      if (files.length > 1) {
        return this.$store.commit('simplealert/open', '1개 파일만 가능합니다.');
      }

      const fileInfo = files[0];
      const returnVal = this.mp3Filter(fileInfo);
      console.log(fileInfo);
      if (returnVal) this.fileInfo = fileInfo;
    },
    uploadMp3(file) {
      // const form = new FormData();
      const fileInfo = file || this.$refs.mp3_input.files[0];
      const returnVal = this.mp3Filter(fileInfo);
      if (returnVal) this.fileInfo = fileInfo;
    },
    clickInputTag() {
      this.$refs.mp3_input.click();
    },
    removeFile() {
      this.fileInfo = '';
      this.$refs.mp3_input.value = '';
    },
    removeName() {
      this.filename = '';
    },
    nextButton() {
      if (this.fileInfo === '') return;
      if (this.filename === '' && !this.isFilename) {
        // this.uploadChosenFileFnc();
        this.handleDebounce(this.uploadChosenFileFnc);
      } else {
        if (this.filename === '') {
          this.$refs.name_input.focus();
          this.$store.commit('simplealert/open', '파일 이름을 입력하세요.');
        } else if (this.filename.length > 11) {
          return this.$refs.name_input.focus();
        } else {
          this.handleDebounce(this.registMySoundByFileFnc);
        }
      }
    },
    inputName(e) {
      const text = e.target.value;

      if (text.length > 10) {
        this.isWarn = true;
        this.filename = text.slice(0, 10);
        setTimeout(() => {
          this.isWarn = false;
        }, 1000);
      } else {
        this.isWarn = false;
        this.filename = text;
      }
    },

    async uploadChosenFileFnc() {
      this.isLoding = true;
      const form = new FormData();
      form.append('corpId', VueCookies.get('corpId'));
      form.append('brandId', VueCookies.get('brandId'));
      form.append('fileToUpload', this.fileInfo);
      try {
        const returnVal = await uploadChosenFile(form);
        if (returnVal.data.resultCd === '0000') {
          var result = returnVal.data.result;
          this.fileUploadInfo = result;
          this.isFilename = true;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoding = false;
      }
    },
    async registMySoundByFileFnc() {
      this.isLoding = true;
      const param = {
        corpId: VueCookies.get('corpId'),
        brandId: VueCookies.get('brandId'),
        soundTitle: this.filename,
        tempNo: this.fileUploadInfo.tempNo,
        playtime: this.fileUploadInfo.playtime,
      };
      this.filename = '';
      try {
        const returnVal = await registMySoundByFile(param);
        if (returnVal.data.resultCd === '0000') {
          this.resetInfo();
          this.$store.commit('simplealert/openFinal', '파일이 등록되었습니다.');
        }
      } catch (e) {
        console.error(e.response.data);
        this.$store.commit('simplealert/open', e.response.data.resultMsg);
      } finally {
        this.isLoding = false;
      }
    },

    handleDebounce(func) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (typeof func === 'function') {
          func();
        }
      }, 500);
    },
  },
};
</script>

<style scoped>
@media all and (max-width: 500px) {
  .single-file-check p {
    font-size: 15px !important;
    color: #ff2d55;
    font-weight: 400;
  }
}
.file_wrap {
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
}
.file_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 540px;
  height: 780px;
  border-radius: 16px;
  background: linear-gradient(0deg, #151515, #151515), linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08));
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.8);
  text-align: Center;
}
.file_box .file_header {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 32px 32px 0 32px;
  padding: 2px 12px;
}

.file_header .less_than {
  cursor: pointer;
  display: inline-block;
  width: 10px;
  height: 18px;
}

.file_header p {
  position: absolute;
  top: -7px;
  left: 40px;
  font-size: 24px;
  font-weight: 700;
  line-height: 34.75px;
  color: #ffffff;
}
.file_header img {
  margin: auto 0;
  width: 18px;
  height: 18px;
}
.file_box .file_view {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 24px 32px 0 32px;
  padding: 11px 24px;

  width: 476px;
  height: 60px;
  border: 1px solid #ffffff4d;
  border-radius: 8px;
  background-color: #ffffff14;
}

.file_view .red_music {
  display: inline-block;
  margin-right: 26px;
  width: 38px;
  height: 38px;
}
.file_view p {
  position: absolute;
  top: 16px;
  left: 86px;
  font-size: 16px;
  font-weight: 350;
  line-height: 23.17px;
  color: #ffffff;
}
.file_view img {
  cursor: pointer;
  margin: auto 0;
  width: 14.4px;
  height: 14.4px;
}
.name_wrap {
  position: relative;
  margin: 0 32px;
  height: 60px;
}

.name_wrap .file_name {
  position: relative;
  padding: 19px 24px;

  width: 476px;
  height: 60px;
  border: 1px solid #ffffff4d;
  border-radius: 8px;
  background-color: #ffffff14;

  font-size: 15px;
  font-weight: 500;
  line-height: 60px;
}
.border_red {
  border-color: #ff2d55 !important;
}
.name_wrap input:focus {
  outline: none;
  box-shadow: 0 0px 0px 0 #ffffff4d;
}
.opacity_30 {
  opacity: 30%;
}
.name_wrap img {
  cursor: pointer;
  position: absolute;
  right: 28px;
  width: 14.4px;
  height: 14.4px;
  top: 50%;
  transform: translateY(-50%);
}
.text_warn {
  margin: 5px 24px;
  text-align: start;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.27px;
  color: #ff1150;
}
.file_box .upload_text {
  margin-top: 60px;
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28.96px;
  color: #ffffff;
}
.file_box .file_upload {
  margin: 0 auto;
  padding: 32px;
  width: 476px;
  height: 200px;
  border: 2px dotted #ffffff4d;
  border-radius: 8px;
  opacity: 0.7;
  cursor: pointer;
}
.file_upload:hover {
  opacity: 1;
}
.file_upload img {
  margin: 0 auto;
  width: 82px;
  height: 82px;
}
.file_upload p {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.27px;
  text-align: center;
  color: #ffffff;
}
.file_upload .mp3_input_box {
  display: none;
}

.next_button {
  position: absolute;
  bottom: 32px;
  right: 32px;

  width: 120px;
  height: 60px;
  border-radius: 8px;
  background-color: #ff2d55;

  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  line-height: 26.06px;
}

@media (max-width: 1536px) {
  .file_box {
    width: 432px;
    height: 624px;
  }
  .file_box .file_header {
    margin: 25.6px 25.6px 0 25.6px;
    padding: 2px 12px;
  }

  .file_header .less_than {
    width: 8px;
    height: 14.4px;
  }

  .file_header p {
    left: 32px;
    font-size: 19.2px;
  }
  .file_header img {
    width: 14.4px;
    height: 14.4px;
  }
  .file_box .file_view {
    margin: 19.2px 25.6px 0 25.6px;
    padding: 8.8px 19.2px;

    width: 380.8px;
    height: 48px;
  }

  .file_view .red_music {
    margin-right: 20.8px;
    width: 30.4px;
    height: 30.4px;
  }
  .file_view p {
    top: 12.8px;
    left: 68.8px;
    font-size: 12.8px;
    line-height: 18.5px;
  }
  .file_view img {
    width: 14.4px;
    height: 14.4px;
  }
  .name_wrap {
    margin: 0 25.6px;
    height: 48px;
  }

  .name_wrap .file_name {
    padding: 15.2px 19.2px;

    width: 380.8px;
    height: 48px;

    font-size: 12px;
    line-height: 48px;
  }

  .name_wrap img {
    right: 22.4px;
    width: 14.4px;
    height: 14.4px;
    top: 50%;
    transform: translateY(-50%);
  }
  .text_warn {
    margin: 4px 19.2px;
    font-size: 11.2px;
    line-height: 16.2px;
  }
  .file_box .upload_text {
    margin-top: 48px;
    margin-bottom: 19.2px;
    font-size: 16px;
    line-height: 23px;
  }
  .file_box .file_upload {
    padding: 25.6px;
    width: 380.8px;
    height: 160px;
  }
  .file_upload img {
    width: 65.6px;
    height: 65.6px;
  }
  .file_upload p {
    margin-top: 12.8px;
    font-size: 11.2px;
    line-height: 16.2px;
  }

  .next_button {
    bottom: 25.6px;
    right: 25.6px;

    width: 96px;
    height: 48px;

    font-size: 14.4px;
    line-height: 20.8px;
  }
}
</style>
